<template>
  <div>
    <h1 class="text-left">FAQ</h1>
    <div class="accordion text-left" role="tablist">
      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button class="b1" style="background-color: #0000ff; border-color: #0000ff" block v-b-toggle.accordion-1 variant="info">{{ $t('faq.whatIsAPVRTicket') }}</b-button>
        </b-card-header>
        <b-collapse id="accordion-1" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>{{ $t('faq.whatIsAPVRTicketText') }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button class="b1" block v-b-toggle.accordion-2 variant="info">{{ $t('faq.whatDoesThePVRTicketDo') }}</b-button>
        </b-card-header>
        <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>{{ $t('faq.whatDoesThePVRTicketDoText') }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button class="b1" block v-b-toggle.accordion-3 variant="info">{{ $t('faq.insertTicketWithinThreeDays') }}</b-button>
        </b-card-header>
        <b-collapse id="accordion-3" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>{{ $t('faq.insertTicketWithinThreeDaysText') }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button class="b1" block v-b-toggle.accordion-4 variant="info">
            {{ $t('faq.iHavePurchasedTicket') }}
          </b-button>
        </b-card-header>
        <b-collapse id="accordion-4" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>{{ $t('faq.iHavePurchasedTicketText') }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button class="b1" block v-b-toggle.accordion-5 variant="info">
            {{ $t('faq.buyWrongTicket') }}
          </b-button>
        </b-card-header>
        <b-collapse id="accordion-5" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>{{ $t('faq.buyWrongTicketText') }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>

      <b-card no-body class="mb-1">
        <b-card-header header-tag="header" class="p-1" role="tab">
          <b-button class="b1" block v-b-toggle.accordion-6 variant="info">
            {{ $t('faq.pvrNotWork') }}
          </b-button>
        </b-card-header>
        <b-collapse id="accordion-6" accordion="my-accordion" role="tabpanel">
          <b-card-body>
            <b-card-text>{{ $t('faq.pvrNotWorkText') }}</b-card-text>
          </b-card-body>
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "FAQ",
  data() {
    return {
      text: `
         A PVR ticket is a secret code that is generated for a specific STB only.
         Thus, each STB has its own code. Also the time when the key code is generated is taken into account.\n
         Therefore the PVR ticket that is generated for a given MAC address can only be used on a STB that has this unique MAC address.
         It does not work on other devices.\n
         The PVR ticket has to be inserted into the STB within a given time of maximal 4 days.
         Later on the PVR ticket is not valid any more.
        `,
      text2: `
        The PVR ticket is a secret code that applies only to your STB system and has to be entered in a specific menu of the STB to activate the
        PVR functionality. This PVR functionality allows you to do recordings on a connected USB storage
        device of suitable capacity and performance.\n
        The PVR ticket is bought on this web page and has to be inserted into the STB within 4 days after purchase.
        `,
      text3: `
         A PVR ticket that is not inserted within 4 days after purchase becomes invalid. Thus,
         the STB will not accept it anymore and therefore it is useless.
         So therefore make sure you enter it in time when it is purchased.
        `,
      text4: `
        First you have to check if the purchase was done recently and the PVR ticket is no older than 4 days.
        If it is not then please also check if the STB is connected to a broadcast network and has a valid live TV signal so
        that the STB also receives a valid time from there. As the algorithms to activate the PVR gets the time
        from the PVR ticket and compares to its local time, the STB must have a valid time so that the STB does
        not think the 4 days have already passed by.
        `,
      text5: `
         If you bought the PVR ticket for the wrong MAC address there is no way of get any reimbursement of the costs for this PVR ticket.
         Therefore please check carefully if the correct MAC address is entered.
        `,
      text6: `
         You have to connect a USB storage device of suitable size (storage capacity) and read/write performance.
         Please make sure that the USB storage device is formatted with the methods integrated into the STB (if there are).
         In any case please check the detailed instructions of the STB for PVR usage.
        `
    }
  }
}
</script>

<style scoped>

.b1{
  background-color: #0000ff!important;
  border-color: #0000ff!important;
}

.b1:hover{
  background-color: #0000aa!important;
  border-color: #0000aa!important;
}

</style>
